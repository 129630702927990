.bet-menu {
  $bgColorLevel0: #1a1a1a;
  $bgColorLevel1: #272727;

  width: 100%;

  a {
    color: #fff;
  }

  &__children {
    min-width: 200px;

    &.visible {
      display: block;
    }
  }

  &--level-0 {
    .bet-menu__children {
      left: 0;
      margin-left: -$pageGutter;
      background-color: $bgColorLevel0;
    }
  }

  &--level-1 {
    display: flex;
    position: relative;
    align-items: center;
    height: 100%;

    .bet-menu__children {
      top: 5px;
      left: 45%;
      overflow-y: auto;
      transform: scale(0, 0);
      transform-origin: top left;
      background-color: $bgColorLevel1;
    }
  }

  .menu-item + .menu-item {
    &::before {
      content: '';
      display: block;
      height: 1px;
      margin: 0 16px;
      background-color: #4a4a4a;
    }
  }

  .menu-item__wrapper {
    position: relative;
  }

  .menu-item__buttons {
    display: flex;
    position: absolute;
    z-index: 1;
    top: 8px;
    right: 12px;
    align-items: center;
  }

  .link-button,
  .submenu-button {
    transition: background-color 0.25s;
    border-left: solid 5px transparent;

    &.has-active-reduction {
      border-left: solid 5px #0096ff;
    }

    &__inner {
      width: 100%;
      padding: 10px 16px 11px 10px;
    }
  }

  &--level-0 .link-button:not(.disabled):hover,
  &--level-1 .submenu-button:hover {
    background-color: darken($bgColorLevel0, 5);
  }

  &--level-1 .link-button:not(.disabled):hover {
    background-color: darken($bgColorLevel1, 5);

    .icon-addcircle {
      fill: darken(#0096ff, 10);
    }
  }

  .link-button {
    display: block;
    font-size: 14px;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    &.disabled {
      opacity: $disabled-opacity;
      cursor: not-allowed;
    }

    &.is-add-reduction {
      color: #0096ff;

      .icon-addcircle {
        margin-right: 6px;
      }

      .link-button__inner {
        display: flex;
        align-items: center;
      }
    }
  }

  .submenu-button {
    width: 100%;
    color: #fff;
    text-align: left;

    &__inner {
      display: flex;
      align-items: center;

      .title {
        flex: 1;
        font-size: 14px;
      }
    }
  }

  .betmenu__toggle {
    margin-right: 3px;
  }

  // Override react-toggle styles
  .betmenu__toggle.react-toggle {
    $uncheckedTrackColor: #9b9b9b;
    $checkedTrackColor: #0096ff;

    .react-toggle-track {
      width: 34px;
      height: 19px;
      border-radius: 12px;
      background-color: $uncheckedTrackColor;
    }

    .react-toggle-thumb {
      top: 2px;
      left: 2px;
      width: 15px;
      height: 15px;
      border: 0;
      background-color: #272727;
      box-shadow: 0 1px 1px 0 rgba(123, 123, 123, 0.5);
    }

    &.react-toggle--disabled {
      opacity: 0.3;
    }

    &:hover:not(.react-toggle--disabled) .react-toggle-track {
      background-color: darken($uncheckedTrackColor, 10);
    }

    &.react-toggle--checked {
      &:hover:not(.react-toggle--disabled) .react-toggle-track {
        background-color: darken($checkedTrackColor, 10);
      }

      .react-toggle-track {
        background-color: $checkedTrackColor;
      }

      .react-toggle-thumb {
        left: 17px;
        background-color: #fff;
      }
    }
  }

  .icon-trash,
  .icon-addcircle {
    transition: fill 0.25s;
    fill: #0096ff;
  }

  .betmenu__trashButton:not(:disabled):hover {
    .icon-trash {
      fill: darken(#0096ff, 10);
    }
  }

  @include breakpoint($breakSmall) {
    &--level-1 {
      .bet-menu__children {
        left: 100%;
      }
    }
  }

  @include breakpoint($breakMedium) {
    .title {
      font-size: 14px;
    }
  }
}
