@import 'breakpoint-sass/stylesheets/breakpoint';
@import 'normalize-scss/sass/normalize';
@import 'shared/styles/core/settings';

@include normalize();

@import 'shared/styles/core/mixin';
@import 'shared/styles/core/sprites-mixin'; // TODO - refactor to svg and remove

:global {
  @import 'shared/styles/core/sprites'; // TODO - refactor to svg and remove
  @import 'shared/styles/core/icons';
  @import 'shared/styles/core/util';
  @import 'shared/styles/core/defaults';
  @import 'shared/styles/core/typography';
  @import 'shared/styles/core/misc';
  @import 'shared/styles/core/flex-grid';
  @import 'shared/styles/core/buttons';

  @import 'shared/styles/components/game-colors';
  @import 'shared/styles/components/layout/index';
  @import 'shared/styles/components/menu/index';
  @import 'shared/styles/components/content/index';
  @import 'shared/styles/components/input/index';
  @import 'shared/styles/components/button/index';
  @import 'shared/styles/components/loader/index';
  @import 'shared/styles/components/dialog/index';
  @import 'shared/styles/components/login/index';
  @import 'shared/styles/components/betHeader/index';
  @import 'shared/styles/components/Instructions';
  @import 'shared/styles/components/ErrorInfo';
  @import 'shared/styles/components/UrlSharer';
  @import 'shared/styles/components/ComponentLoadingSection';
  @import 'shared/styles/components/SiteMessages';
  @import 'shared/styles/components/ProgressBar';
  @import 'shared/styles/components/ExpandContentSymbol';
}
