.clear {
  clear: both;
}

.align {
  &.x {
    width: 100%;
    > div {
      text-align: center;
    }
  }

  &.y {
    display: table;
    height: 100%;
    border-spacing: 0;
    > div {
      display: table-cell;
      vertical-align: middle;
    }
  }
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.relative {
  position: relative;
}

.table-cell {
  display: table-cell;
  vertical-align: middle;
}

.text-nowrap {
  white-space: nowrap;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.h100 {
  height: 100%;
}

.flex {
  display: flex;
  width: 100%;

  &--columns {
    flex-direction: column;
  }

  &--row-center {
    align-items: center;
  }

  &--column-center {
    justify-content: center;
  }

  &--wrap {
    flex-wrap: wrap;
  }

  &--full-height {
    height: 100%;
  }
}

.flex-1 {
  flex: 1;
}

// https://github.com/prettier/prettier/issues/9339
// prettier-ignore
@keyframes :global(fadeIn) {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
