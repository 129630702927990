.register-header {
  position: relative;
  margin: 24px 0;
  color: #333;
  font-size: 20px;

  img {
    $imgWidth: 25px;
    $imgHeight: 42px;
    position: absolute;
    bottom: -($imgHeight + 2px);
    left: calc(50% - #{$imgWidth/2});

    width: $imgWidth;
    height: $imgHeight;
  }

  @include breakpoint($breakMedium) {
    margin-top: 34px;
  }
}
