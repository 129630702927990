.progressbar {
  display: flex;
  height: 0;
  overflow: hidden;
  animation-name: progressbar;
  animation-duration: 0.3s;
  animation-timing-function: ease;
  animation-delay: 1s;
  animation-iteration-count: 1;
  background: #ddd;
  color: #000;
  font-size: 10px;
  animation-fill-mode: forwards;
  @extend %fontSemiBold;

  &__inner {
    transition: width 0.25s ease;
    background: #023b65;
    color: #fff;
    text-align: right;
  }

  &__percent {
    display: inline-block;
    padding: 4px 4px 0;
  }
}

// https://github.com/prettier/prettier/issues/9339
// prettier-ignore
@keyframes :global(progressbar) {
  to {
    height: 18px;
  }
}
