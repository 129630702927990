.register {
  padding-top: 26px;

  .product-heading {
    margin: 0 0 12px;
    font-size: 22px;
    @extend %fontExtraBold;

    &__name {
      display: block;
    }

    &__color {
      color: #fc6e09;
    }
  }

  .product-body {
    @extend %fontNormal;
    line-height: 22px;
    &--bold {
      @extend %fontBold;
    }
  }
  .register-button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    font-size: 14px;
    @extend %fontSemiBold;
  }

  @include breakpoint($breakMedium) {
    .product-heading {
      margin: 0 0 16px;
      font-size: 26px;
      &__name {
        display: inline-block;
        margin-right: 7px;
      }
    }

    .register-button {
      margin-top: 22px;
    }
  }
}
