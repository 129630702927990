$cMenuBlue: #1b89c6;

@mixin menuButtonColor($color, $activeColor, $activeBgColor: #fff) {
  color: $color;

  .svg-icon {
    transition: fill 0.25s ease;
    fill: $color;
  }

  &:not(.disabled) {
    &.active {
      &::after {
        background-color: $activeBgColor;
      }
    }

    &:active {
      color: $activeColor;
      .svg-icon {
        fill: $activeColor;
      }
      &::after {
        background-color: transparent;
      }
      &.active {
        &::after {
          background-color: $activeColor;
        }
      }
    }

    @include hover {
      color: $activeColor;
      .svg-icon {
        fill: $activeColor;
      }
      &::after {
        background-color: transparent;
      }
      &.active {
        &::after {
          background-color: $activeColor;
        }
      }
    }
  }
}

.menu {
  background-color: #000;

  &__inner {
    display: flex;
    position: relative;
    align-items: center;
    height: $menu-height;
  }

  .logo-wrapper {
    flex: 0 0 80px;
    padding-right: 26px;
    line-height: 1;

    a {
      display: block;
    }

    img {
      width: 100%;
      height: auto;
    }
  }

  .menu-wrapper {
    flex: 1 1 auto;
  }

  .menu-button {
    display: inline-block;
    position: relative;
    transition-property: color, opacity;
    transition-duration: 0.25s;
    transition-timing-function: ease;
    color: #fff;
    font-weight: $font-weight-semi-bold;
    text-decoration: none;
    white-space: nowrap;

    &::after {
      content: '';
      position: absolute;
      transition: 0.25s background-color ease;
      background-color: transparent;
    }

    @include menuButtonColor(#fff, $cMenuBlue);

    &.disabled {
      opacity: $disabled-opacity;
      cursor: default;
    }
  }

  a.menu-button {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  .icon-checkcircle,
  .icon-user,
  .icon-update {
    margin-right: 4px;
  }
}

.menu-item--atg_export .menu-button,
.menu-item--atg_manual .menu-button,
.menu-item--sport_export .menu-button {
  @include menuButtonColor(#8ab53e, #6e9132, #6e9132);
}

.menu-list {
  display: flex;
  width: 100%;

  .menu-item {
    display: inline-block;
    position: relative;
    flex: 0 0 auto;

    & + .menu-item {
      padding-left: 14px;
    }

    &--burger {
      margin-left: auto;
    }
  }

  .menu-button {
    font-size: 10px;

    &::after {
      bottom: 0;
      left: -2px;
      width: calc(100% + 4px);
      height: 3px;
    }

    &__inner {
      height: $menu-height;
    }
  }

  .title {
    text-transform: uppercase;
  }

  .title-username {
    max-width: 175px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.popup-menu-list {
  position: absolute;
  z-index: 300;
  top: $menu-height;
  left: 0;
  width: 100%;
  transform: scale(1, 0);
  transform-origin: center -5px;
  transition-property: transform, opacity;
  transition-duration: 0.25s;
  transition-timing-function: ease-out;
  opacity: 0;
  background-color: #000;
  box-shadow: 0 5px 7px 0 rgba(0, 0, 0, 0.2);

  &.active {
    transform: scale(1, 1);
    opacity: 1;
  }

  .menu-button {
    width: 100%;
    padding-left: 16px;
    font-size: 14px;

    &::after {
      top: 0;
      left: 0;
      width: 3px;
      height: 100%;
    }

    &__inner {
      height: 30px;
    }
  }
}

@include breakpoint($breakMedium) {
  .menu__inner {
    height: $menu-height-medium;

    .logo-wrapper {
      flex-basis: 96px;
    }
  }

  .popup-menu-list {
    top: $menu-height-medium;
    right: 0;
    left: auto;
    width: auto;
    .menu-button {
      padding: 0 16px;
    }
    .menu-item {
      &--logout {
        margin: 10px 0 0;
        border-top: 1px solid #8c8c8c;

        .menu-button__inner {
          height: 46px;
        }
      }
    }
  }

  .menu-list {
    .menu-item {
      &--burger {
        display: none;
      }
    }

    .menu-button {
      padding: 0;
      &__inner {
        height: $menu-height-medium;
      }
    }
  }
}

@include breakpoint($breakLarge) {
  .menu__inner {
    height: $menu-height-big;

    .logo-wrapper {
      flex-basis: 156px;
      padding-right: 50px;
    }
  }

  .menu-list {
    .menu-item + .menu-item {
      padding-left: 18px;
    }

    .menu-item::after {
      top: 18%;
      bottom: 18%;
    }

    .menu-button {
      font-size: 14px;
      &__inner {
        height: $menu-height-big;
      }
    }
  }

  .popup-menu-list {
    top: $menu-height-big;
  }
}

@include breakpoint($breakXLarge) {
  .menu-list {
    .menu-item + .menu-item {
      padding-left: 22px;
    }
  }
}

.popup-profile-data {
  margin: 0 0 7px;
  background-color: #333;
  color: #fff;

  .profile-data {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 18px 16px;

    .texts {
      .profile-name {
        @extend %fontBold;
        padding: 0 0 5px;
        font-size: 16px;
        line-height: 18px;
      }

      .subscription {
        font-size: 12px;
        line-height: 18px;
      }
    }

    .profile-img {
      position: relative;

      &.default {
        &::after {
          @extend %fontBold;
          content: '+';
          position: absolute;
          right: 0;
          bottom: 0;
          width: 17px;
          height: 17px;
          padding: 1px 0 0 1px;
          overflow: hidden;
          transition-duration: 0.1s;
          border-radius: 50%;
          background-color: #fff;
          box-shadow: 0 0 4px rgba(#000, 0.3);
          color: #009aff;
          font-size: 14px;
          text-align: center;
          cursor: pointer;
        }
      }

      img {
        width: 45px;
        height: 45px;
        border-radius: 50%;
      }

      &:hover {
        .icon,
        &.default::after {
          background-color: #009aff;
          fill: #fff;
          color: #fff;
        }
      }

      a {
        display: block;
        position: relative;

        .icon,
        &::after {
          @extend %fontBold;
          position: absolute;
          right: 0;
          bottom: 0;
          width: 18px;
          height: 18px;
          padding: 3px;
          overflow: hidden;
          transition-duration: 0.1s;
          border-radius: 50%;
          background-color: #fff;
          box-shadow: 0 0 4px rgba(#000, 0.3);
          fill: #009aff;
          font-size: 14px;
          text-align: center;
          cursor: pointer;
        }
      }
    }
  }
}

.menu-profile-live {
  padding: 18px 16px;
  border-top: 1px solid #1c1c1c;
  font-size: 14px;

  .alias {
    padding: 5px 0 15px;
    a {
      color: #fff;
      text-decoration: underline;
    }
  }

  .play {
    display: flex;
    align-items: center;

    .toggle {
      margin: 0 10px 0 0;
      $uncheckedTrackColor: #9b9b9b;
      $checkedTrackColor: #0096ff;

      .react-toggle-track {
        width: 34px;
        height: 19px;
        border-radius: 12px;
        background-color: $uncheckedTrackColor;
      }

      .react-toggle-thumb {
        top: 2px;
        left: 2px;
        width: 15px;
        height: 15px;
        border: 0;
        background-color: #272727;
        box-shadow: 0 1px 1px 0 rgba(123, 123, 123, 0.5);
      }

      &.react-toggle--disabled {
        opacity: 0.3;
      }

      &:hover:not(.react-toggle--disabled) .react-toggle-track {
        background-color: darken($uncheckedTrackColor, 10);
      }

      &.react-toggle--checked {
        &:hover:not(.react-toggle--disabled) .react-toggle-track {
          background-color: darken($checkedTrackColor, 10);
        }

        .react-toggle-track {
          background-color: $checkedTrackColor;
        }

        .react-toggle-thumb {
          left: 17px;
          background-color: #fff;
        }
      }
    }
  }
}
