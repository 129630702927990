.betinfo {
  border-left-width: 0 !important;

  .betheader-button {
    display: none;
  }

  .icon-wrapper--expand {
    margin-left: auto;
  }

  .indicator {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 0;
    height: 100%;
    overflow: hidden;
    transition: 0.3s width ease;
    cursor: pointer;

    &--visible {
      width: 32px;
      border-left-width: 1px;
      border-left-style: solid;
      border-left-color: inherit;
    }

    .sprite {
      width: 16px;
      height: 16px;
    }
  }

  .betinfo-popup {
    @extend %overflow-vertical-no-custom;
    min-width: calc(100% - 20px);
    padding-bottom: 12px;

    .betinfo-popup-wrapper {
      padding: 0 $pageGutter;
    }

    h2 {
      margin: 16px 0 8px;
      font-size: 18px;
      font-weight: normal;
    }
  }

  @include breakpoint($breakSmall) {
    flex: 1 1 auto;
    border-left-width: 1px !important;

    .betheader-button {
      display: block;
      flex: 1 1 auto;
    }

    .indicator--visible {
      width: 26px;
      padding-left: 8px;
      border-left: 0;
    }

    .betinfo-popup {
      min-width: 364px;
    }
  }

  @include breakpoint(850px) {
    flex: none;
    margin-right: 4px;

    .betheader-button {
      border-right-width: 1px;
      border-right-style: solid;
      border-color: inherit;
    }

    .betinfo-popup-betdata {
      display: none;
    }
  }
}
