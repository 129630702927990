svg[class^='icon-'],
.svg-icon {
  display: inline-block;
  vertical-align: middle;
}

.icon-menu {
  width: 25px;
  height: 19px;
}

.icon-edit,
.icon-locked,
.icon-unlocked {
  width: 14px;
  height: 14px;
}

.icon-archive,
.icon-event,
.icon-checkcircle,
.icon-update,
.icon-trash,
.icon-link,
.icon-addcircle,
.icon-notification {
  width: 20px;
  height: 20px;
}

.icon-close,
.icon-checkmark,
.icon-open,
.icon-duplicate,
.icon-cached,
.icon-error-circle,
.icon-search,
.icon-tune,
.icon-warning,
.icon-info,
.icon-download,
.icon-reply,
.icon-question,
.icon-undo,
.icon-upload {
  width: 18px;
  height: 18px;
}

.icon-share-fb,
.icon-share-twitter,
.icon-share-email {
  width: 31px;
  height: 31px;
}

.icon-double-arrow-left {
  width: 9px;
  height: 9px;
}

.icon-open-analysis,
.icon-quote,
.icon-clock,
.icon-compare,
.icon-arrowforward {
  width: 12px;
  height: 12px;
}

.icon-user,
.icon-chevron-down {
  width: 16px;
  height: 16px;
}

.icon-menu,
.icon-edit,
.icon-open,
.icon-duplicate,
.icon-trash,
.icon-locked,
.icon-link,
.icon-unlocked,
.icon-arrowforward,
.icon-notification,
.icon-download,
.icon-reply,
.icon-upload {
  fill: #fff;
}

.icon-undo {
  transform: scaleX(-1);
}

.icon-checkmark {
  fill: #4a4a4a;
}

.icon-archive,
.icon-event {
  fill: #003494;
}

.icon-error-circle {
  fill: #b7300e;
}

.icon-chevron-right {
  width: 27px;
  height: 64px;
  fill: #ddd;
}

.icon-star {
  width: 27px;
  height: 25px;

  &.white {
    fill: #fff;
  }

  &.yellow {
    fill: #ef8933;
  }
}

.icon-open-analysis,
.icon-quote,
.icon-clock,
.icon-compare {
  fill: #9b9b9b;
}

@include breakpoint($breakMedium) {
  .icon-open-analysis,
  .icon-quote,
  .icon-clock,
  .icon-compare {
    width: 16px;
    height: 16px;
  }
}

@include breakpoint($breakLarge) {
  .icon-locked,
  .icon-unlocked {
    width: 16px;
    height: 16px;
  }

  .icon-user {
    width: 18px;
    height: 18px;
  }

  .icon-close,
  .icon-checkmark {
    width: 24px;
    height: 24px;
  }
}
