.editorial {
  font-size: 14px;
  line-height: 1.5;

  h2 {
    @extend %editorialH2;
    margin: 0 0 16px;

    & + .link-list {
      margin-top: 24px;
    }
  }

  h3 {
    margin: 0 0 8px;
    font-size: 18px;
    font-weight: normal;
    line-height: 1.15;
  }

  h4 {
    margin: 0 0 4px;
    font-size: 14px;
    line-height: 1.15;
    @extend %fontBold;
  }

  &--preamble {
    margin-bottom: 24px;
    font-size: 18px;
  }

  p + h2 {
    margin-top: 24px;
  }

  p.text-small {
    font-size: 12px;
  }

  p,
  ul,
  ol,
  .media-embed {
    margin-top: 0;
    margin-bottom: 16px;
  }

  *:last-child {
    margin-bottom: 0;
  }

  ul {
    list-style-position: inside;
    list-style-type: none;

    li {
      position: relative;
      padding-left: 16px;

      & + li {
        margin-top: 8px;
      }

      &::before {
        content: '';
        display: inline-block;
        position: absolute;
        top: 8px;
        left: 0;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: #4e4e4e;
      }
    }
  }

  ul.link-list {
    li {
      position: static;
      padding-left: 0;

      &::before {
        display: none;
      }

      a {
        display: flex;
        align-items: center;
      }

      .icon-arrowforward {
        width: 18px;
        height: 18px;
        margin-right: 6px;
        fill: $linkText;
      }

      & + li {
        margin-top: 12px;
      }
    }
  }

  .media-embed {
    position: relative;
    height: 0;
    padding-bottom: 56.25%; // 16x9 ratio

    > * {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  &--small-text {
    font-size: 12px;
    line-height: 1.333333333;

    h4 {
      font-size: 12px;
    }
  }

  @include breakpoint($breakLarge) {
    &--responsive {
      font-size: 16px;

      h2 {
        font-size: 26px;
      }

      h3 {
        font-size: 20px;
      }

      h4 {
        font-size: 16px;
      }

      .editorial--preamble {
        margin-bottom: 36px;
        font-size: 22px;
      }

      &.editorial--small-text {
        font-size: 14px;

        h4 {
          font-size: 14px;
        }
      }
    }
  }
}
