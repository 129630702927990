.reduction-report {
  & + .reduction-report {
    margin-top: 11px;
    border-top: solid #4a4a4a 1px;
  }

  h3 {
    margin: 11px 0 8px;
    font-size: 13px;
    @extend %fontSemiBold;
  }

  ul,
  p {
    margin: 0;
    padding: 0;
    font-weight: normal;
    line-height: 1.5;
  }

  .row {
    & + .row {
      margin-top: 8px;
    }

    &--incomplete {
      .optional-line {
        display: flex;
        align-items: center;
        @extend %fontSemiBold;

        &::before {
          content: '';
          display: inline-block;
          width: 6px;
          height: 6px;
          margin-right: 5px;
          border-radius: 50%;
          background-color: #d91e1c;
        }
      }
    }
  }

  span + span {
    &::before {
      content: '\2027';
      display: inline-block;
      margin: 0 3px;
    }
  }

  .subHeader {
    margin-top: -6px;
    margin-bottom: 4px;
    font-size: 13px;

    button {
      margin-left: 6px;
      padding: 8px 6px;
      transition: background-color 0.25s;
      border-radius: 3px;
      background: #1a1a1a;
      color: #ddd;
      font-weight: bold;

      &:hover {
        background: darken(#1a1a1a, 3);
      }
    }
  }
}
