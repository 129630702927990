.error-info {
  display: flex;
  width: 100%;
  background-color: #fde5e5;
  color: #333;

  &--small {
    margin: 16px 0;
    padding: 12px 14px;
  }

  &--big {
    padding: 20px 16px;
    border: solid 1px #f6d6d2;
  }

  p {
    margin-bottom: 8px;
  }

  .retry-button {
    padding: 4px 6px;
    border-radius: 3px;
    background: #b72f0d;
    color: #fff;
    font-size: 12px;
  }

  .icon-error-circle {
    flex-shrink: 0;
    align-self: baseline;
    margin-right: 12px;

    &.with-heading {
      margin-top: 3px;
    }
  }
}
