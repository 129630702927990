.site-messages {
  position: fixed;
  z-index: 150;
  bottom: 0;
  left: 0;
  width: 100%;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.25);

  .item {
    position: relative; // Create stacking order for better transition
    transition-property: transform, opacity;
    transition-duration: 0.25s;
    transition-timing-function: ease-in;

    $negativeColor: #bb2b2b;
    $neutralOrangeColor: #ee9019;
    $neutralBlueColor: #005ca0;
    $positiveColor: #3e801e;

    &--negative {
      background: $negativeColor;
      color: #e8e8e8;
    }

    &--neutral-orange {
      background: $neutralOrangeColor;
      color: #fff;
    }

    &--neutral-blue {
      background: $neutralBlueColor;
      color: #fff;
    }

    &--positive {
      background: $positiveColor;
      color: #fff;
    }

    & + .item {
      border-top-width: 1px;
      border-top-style: solid;

      &.item--negative {
        border-top-color: darken($negativeColor, 10%);
      }

      &.item--neutral-orange {
        border-top-color: darken($neutralOrangeColor, 15%);
      }

      &.item--neutral-blue {
        border-top-color: darken($neutralBlueColor, 15%);
      }

      &.item--positive {
        border-top-color: darken($positiveColor, 15%);
      }
    }

    &__content {
      display: flex;
      align-items: center;
      padding: 14px 0 12px;
    }
  }

  .item-enter {
    transform: translateY(100px);
    opacity: 0;

    &.item-enter-active {
      transform: translateY(0);
      opacity: 1;
    }
  }

  .html-message {
    padding-bottom: 6px;
  }

  .text {
    margin-top: 1px;
    margin-right: 10px;
  }

  .message-link {
    color: #fff;
    text-decoration: underline;
    @extend %fontSemiBold;
  }

  .icon {
    margin-right: 10px;

    .svg-icon {
      fill: #fff;
    }
  }

  .buttons {
    margin-left: auto;
  }

  @include breakpoint($breakLarge) {
    .icon,
    .text {
      margin-right: 20px;
    }
  }
}
