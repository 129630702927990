.login-container {
  max-width: 290px;
  margin: 0 auto;
  text-align: center;

  &--background {
    padding: 20px 15px 32px;
    background-color: #fff;

    & + .login-container--background {
      margin-top: 19px;
    }
  }

  &--border {
    border: 1px solid #ddd;
  }

  &--dialog {
    padding-top: 40px;
  }

  input,
  button,
  .register-button {
    display: block;
    width: 100%;
    max-width: 360px;
    height: 50px;
    margin: 0 auto;
    padding: 0 16px;
    border-radius: 3px;
  }

  button,
  .register-button {
    background-color: #20335d;
    color: #fff;
    &[type='submit'] {
      background-color: #005ca0;
      &:hover {
        background-color: darken(#005ca0, 5%);
      }
    }
  }

  p {
    margin: 0;
    font-size: 14px;
    line-height: 1.5;
  }

  @include breakpoint($breakMedium) {
    max-width: 556px;

    &--background {
      padding-top: 5px;
      padding-bottom: 28px;
    }
  }
}
