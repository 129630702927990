.betheader-item {
  display: flex;
  align-items: center;
  height: 100%;

  .betheader-button {
    padding: 0 5px;
  }

  &--menu {
    flex: 0 0 104px;
  }

  &:first-child {
    .betheader-button {
      padding-left: 0;
    }
  }

  &:last-child {
    .betheader-button {
      padding-right: 0;
    }
  }

  & + .betheader-item {
    border-left-width: 1px;
    border-left-style: solid;
    border-color: inherit;
  }

  @include breakpoint(360px) {
    .betheader-button {
      padding: 0 10px;
    }
  }

  @include breakpoint($breakLarge) {
    &--menu {
      flex-basis: 160px;
    }
  }

  @include breakpoint($breakXLarge) {
    .betheader-button {
      padding: 0 16px;
    }
  }
}
