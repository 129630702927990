// Name prefixed with joker to avoid collision with Bourbon
@mixin joker-transition($duration, $property: all, $ease: ease) {
  transition: $property $duration $ease;
}

@mixin no-transition {
  transition: none;
}

// Name prefixed with joker to avoid collision with Bourbon
@mixin joker-transition-transform($duration, $ease: ease) {
  transition: transform $duration $ease;
}

@mixin touch-only {
  :global(.current-input-touch) & {
    @content;
  }
}

@mixin mouse-only {
  :global(.current-input-mouse) & {
    @content;
  }
}

@mixin hover {
  @include mouse-only {
    &:hover {
      @content;
    }
  }
}

@mixin clearfix {
  &::after {
    content: '';
    display: block;
    clear: both;
  }
}

%custom-scrollbars {
  @include mouse-only {
    &::-webkit-scrollbar-track {
      background: #3e5468;
    }

    &::-webkit-scrollbar-thumb {
      background: #fff;
    }

    &::-webkit-scrollbar {
      width: 12px;
      height: 12px;
    }
  }
}

%overflow-both {
  @extend %custom-scrollbars;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

%overflow-vertical {
  @extend %custom-scrollbars;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

%overflow-vertical-no-custom {
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

%fontThin {
  font-weight: $font-weight-thin;
}

%fontNormal {
  font-weight: $font-weight-normal;
}

%fontSemiBold {
  font-weight: $font-weight-semi-bold;
}

%fontBold {
  font-weight: $font-weight-bold;
}

%fontExtraBold {
  font-weight: $font-weight-extra-bold;
}

%textLink {
  color: $linkText;
  text-decoration: none;
}
