$primary: #1262a9;
$jokerwiz-background-color: #fff;
$spinnerSize: 29px;

.dialog.jokerwiz {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 535px;
  height: 100%;
  margin: 0;
  padding: 48px 15px;
  border-radius: 0;
  background: $jokerwiz-background-color;
  color: #000;
  font-family: $base-font-family;
  font-size: 16px;
  text-align: center;

  @include breakpoint(532px) {
    height: auto;
    padding: 47px 63px 38px;
    border-radius: 4px;
  }

  .close-button,
  .arrow-button,
  .icon-close,
  .icon-arrowforward {
    width: 22px;
    height: 22px;
    fill: #9b9b9b;
  }

  ul {
    text-align: center;
  }

  .btn {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: center;
    min-width: 110px;
    max-width: 126px;
    height: 100%;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    border-radius: 3px;
    color: #fff;
    font-size: 1rem;
    font-weight: $font-weight-bold;
    line-height: 20px;
    user-select: none;

    &__inner-text {
      display: flex;
      flex-flow: column;
      justify-content: center;
      width: 100%;
      height: 55px;
      font-size: 26px;
      font-weight: $font-weight-bold;
      @include breakpoint(532px) {
        height: 63px;
        font-size: 28px;
      }
    }

    &__fullwidth {
      display: flex;
      justify-content: center;
      width: 100%;
      min-width: initial;
      max-width: initial;
      height: 44px;
      color: #4a4a4a;
    }

    &__create-system {
      justify-content: center;
      min-width: 160px;
      max-width: none;
      height: 44px;
      margin: 0 auto;
      border-radius: 3px;
      background-color: $primary;

      .spinner {
        width: $spinnerSize;
        height: $spinnerSize;
        background-size: $spinnerSize;
      }
    }
  }

  .btn__system {
    display: flex;
    flex-flow: column;
    justify-content: center;
    width: 100%;
    height: 55px;
    padding: 10px;
    border-radius: 0 0 3px 3px;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 16px;
    @include breakpoint(532px) {
      height: 63px;
      font-size: 14px;
      line-height: 18px;
    }

    &--V4 {
      border-top: 1px solid darken($productColorV4, 5%);
      background: lighten($productColorV4, 10%);
    }

    &--V64 {
      border-top: 1px solid darken($productColorV64, 5%);
      background: lighten($productColorV64, 10%);
    }

    &--V65 {
      border-top: 1px solid darken($productColorV65, 5%);
      background: lighten($productColorV65, 10%);
    }

    &--V75 {
      border-top: 1px solid darken($productColorV75, 5%);
      background: lighten($productColorV75, 10%);
    }

    &--V86 {
      border-top: 1px solid darken($productColorV86, 5%);
      background: lighten($productColorV86, 10%);
    }

    &--GS75 {
      border-top: 1px solid darken($productColorGS75, 5%);
      background: lighten($productColorGS75, 10%);
    }

    .system-date {
      text-transform: capitalize;
    }
  }

  .shadow-top {
    box-shadow: inset 0 2px 2px -2px rgba(33, 33, 33, 0.5);
  }

  .input-group {
    display: flex;
    position: relative;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
    margin-bottom: 30px;
    @include breakpoint(532px) {
      margin-bottom: 20px;
    }

    .user-input {
      position: relative;
      flex: 1 1 auto;
      width: 1%;
      height: 44px;
      margin-bottom: 0;
      border: 1px solid #ced4da;
      border-right: 0;
      border-top-left-radius: 0.25rem;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0.25rem;
      text-align: left;
    }

    .input-group-append,
    .input-group-prepend {
      display: flex;
    }

    .input-group-append {
      margin-left: -1px;
    }

    .input-group-text {
      display: flex;
      align-items: center;
      margin-bottom: 0;
      padding: 0.375rem 0.75rem;
      border: 1px solid #ced4da;
      border-left: 0;
      border-top-right-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;
      background-color: transparent;
      color: #858585;
      font-size: 1rem;
      font-weight: $font-weight-bold;
      line-height: 1.5;
      text-align: center;
      white-space: nowrap;
    }
  }

  .inner-content {
    @extend %overflow-vertical-no-custom;
    width: 100%;
    height: 100%;

    .create-system-form {
      width: 100%;
    }

    .error {
      grid-column-end: 4;
      grid-column-start: 1;
    }

    .welcome-header {
      margin: 20px 0 10px;
      font-size: 24px;
      font-weight: $font-weight-bold;
      line-height: 30px;
      @include breakpoint(532px) {
        margin: 22px 0 10px;
        font-size: 26px;
        line-height: 32px;
      }
    }

    .available-systems-header {
      margin: 0 0 20px;
      color: #858585;
      font-size: 12px;
      font-weight: $font-weight-bold;
      line-height: 20px;
      @include breakpoint(532px) {
        font-size: 14px;
      }
    }

    .create-system-header {
      margin: 22px 0;
      font-size: 24px;
      font-weight: $font-weight-bold;
      @include breakpoint(532px) {
        font-size: 26px;
      }
    }

    .wiz-grid {
      display: grid;
      grid-gap: 20px;
      grid-template-columns: repeat(auto-fit, 110px);
      justify-content: center;
      min-height: $spinnerSize * 2;

      .spinner {
        width: $spinnerSize;
        height: $spinnerSize;
        background-size: $spinnerSize;
        filter: invert(1);
      }

      @include breakpoint(532px) {
        grid-template-columns: repeat(auto-fit, 126px);
      }
    }

    .risk-header {
      margin: 0 0 14px;
      font-weight: $font-weight-bold;
    }

    .maxprice-header {
      margin: 20px 0 14px;
      font-weight: $font-weight-bold;
    }

    .prices {
      margin-bottom: 0.6rem;
    }

    .system-details {
      margin: 0 0 29px;
      font-size: 14px;
      font-weight: $font-weight-normal;
      line-height: 20px;
      @include breakpoint(532px) {
        margin: 0 0 26px;
      }
    }
  }

  .nowrap {
    flex-wrap: nowrap;
  }

  .back-button .icon-arrowforward {
    fill: #000;
  }

  .risk-level,
  .max-price {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 52px;
    border: 1px solid $jokerwiz-background-color;
    border-radius: 3px;
    background: $jokerwiz-background-color;
    background-color: #ebebeb;
    color: #858585;
    font-size: 15px;
    font-weight: $font-weight-bold;
    @include breakpoint(320px 340px) {
      padding: 6px;
    }

    &:not(:first-child):not(:last-child) {
      border-radius: 0;
    }

    &:first-child {
      border-left: 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    &:last-child {
      border-right: 0;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  .max-price {
    width: 81px;
    @include breakpoint(320px 430px) {
      width: 57px;
    }
  }

  .risk-level {
    width: 134px;
    @include breakpoint(320px 430px) {
      width: 96px;
    }
  }

  .risk-level.active,
  .max-price.active {
    background: $primary;
    button {
      color: #fff;
    }
  }
}
