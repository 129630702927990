.dialog.subscription-required {
  width: auto;
  padding: 0;
  // Override default dialog styles
  background: #fff;
  color: $bodyText;
  a {
    text-decoration: none;
  }
}
