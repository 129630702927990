@import 'breakpoint-sass/stylesheets/breakpoint';
@import 'shared/styles/core/settings';
@import 'shared/styles/core/mixin';

.wrapper {
  max-width: 950px;
  margin: 20px auto 0;
}

.table {
  margin: 30px 0;
  padding: 10px 0 0;
  border: 1px solid #ddd;
  background-color: #fff;
  font-size: 14px;
  line-height: 20px;

  @include breakpoint($breakMobileDown) {
    border: 0;
  }

  @include breakpoint($breakSmallDown) {
    margin: 25px 0;
  }
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 13px 20px;
  border-bottom: 1px solid #e5e5e5;

  @include breakpoint($breakMobileDown) {
    flex-direction: column;
  }

  @include breakpoint($breakSmallDown) {
    padding: 10px;
  }

  &:first-child,
  &:last-child {
    border: 0;
  }
}

.col {
  display: flex;
  flex: 1;

  @include breakpoint($breakMobileDown) {
    width: 100%;
    text-align: center;

    > div {
      margin: 0 auto;
    }
  }

  &.double {
    flex-direction: column;
  }

  .item {
    flex: 1;
    white-space: nowrap;
  }

  .item {
    margin: 5px 10px;

    @include breakpoint($breakSmallDown) {
      margin: 5px;
    }
  }
}

.planHeader {
  display: block;
  position: relative;
  padding: 10px 0;
  font-size: 12px;
  white-space: nowrap;

  &::after {
    content: '';
    position: absolute;
    z-index: 1;
    top: 50%;
    right: 0;
    left: 0;
    height: 1px;
    background-color: #e5e5e5;
  }

  span {
    @extend %fontSemiBold;
    display: inline-block;
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 50%;
    padding: 0 10px;
    transform: translate(-50%, -50%);
    background-color: #fff;
    font-size: 14px;
    line-height: 18px;

    @include breakpoint($breakMobileDown) {
      font-size: 12px;
    }
  }
}

.prices {
  font-size: 16px;
  line-height: 20px;
}

.plan {
  @extend %fontExtraBold;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 90px;
  height: 90px;
  margin: 10px auto;
  background-color: #20335d;
  color: #fff;
  font-size: 14px;
  text-transform: uppercase;

  @include breakpoint($breakSmallDown) {
    width: auto;
  }

  &.sport {
    background-color: #ff8300;
  }
}

.headerWrapper {
  padding: 0 15px;
}

.header {
  @extend %fontExtraBold;
  margin: 0.67em 0 0;
  font-size: 20px;
  line-height: 22px;

  @include breakpoint($breakMobileDown) {
    font-size: 18px;
  }
}

.imgWrapper {
  @include breakpoint($breakMobileDown) {
    display: none;
  }
}

.check {
  display: block;
  width: 9px;
  height: 19px;
  margin: 0 auto;
  transform: rotate(45deg);
  border-right: 4px solid #000;
  border-bottom: 4px solid #000;

  @include breakpoint($breakMobileDown) {
    margin: 7px auto 0;
  }

  &.inactive {
    border-color: #e1e1e1;
  }
}

.btnChoose {
  @extend %fontSemiBold;
  display: block;
  max-width: 90px;
  margin: 0 auto;
  padding: 12px 30px;
  border-radius: 3px;
  background-color: #005ca0;
  color: #fff;
  font-size: 14px;
  text-align: center;

  &.fullWidth {
    width: auto;
    max-width: 100%;
  }

  &:hover {
    background-color: darken(#005ca0, 5%);
  }
}

.txtCenter {
  text-align: center;
}
