.bet-header-popup {
  position: absolute;
  z-index: 300;
  top: $top-info-height;
  height: auto;
  transform: scale(1, 0);
  transform-origin: center 0;
  transition-property: transform, opacity;
  transition-duration: 0.25s;
  transition-timing-function: ease-out;
  opacity: 0;
  background-color: $background;
  box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.5);
  color: #fff;
  font-size: 12px; // TBD
  line-height: 1.3; // TBD

  &--visible {
    transform: scale(1, 1) !important;
    transition-duration: 0.1s;
    opacity: 1;
  }

  @include breakpoint($breakLarge) {
    top: $top-info-height-big;
    font-size: 14px;
  }
}
