.login-form {
  .logo-wrapper {
    width: 144px;
    margin: 14px auto 35px;
  }

  input {
    margin-bottom: 8px;
    border: 1px solid #ddd;
    background-color: #f2f2f2;
    color: #4a4a4a;
    font-size: 14px;
  }

  input + button {
    margin-top: 16px;
  }

  .login-info {
    margin: 16px 0;
    font-size: 14px;

    &.error {
      color: #f00;
    }
  }

  .forgot-wrapper {
    margin-top: 25px;
  }
}
