.betheader-button {
  height: 100%;
  transition: 0.3s opacity ease;
  font-size: 10px;
  text-align: left;
  cursor: pointer;

  &--full-width {
    width: 100%;
  }

  &__inner {
    display: flex;
    position: relative;
    align-items: center;
    width: 100%;
    height: 100%;

    > :first-child {
      flex: 1 1 auto;
    }
  }

  .expand-column {
    padding-left: 8px;
  }

  @include hover {
    opacity: $hover-opacity;
  }

  &.active {
    opacity: $hover-opacity;
  }

  @include breakpoint($breakMedium) {
    font-size: 11px;
  }

  @include breakpoint($breakLarge) {
    font-size: 12px;
  }

  @include breakpoint($breakXLarge) {
    .expand-column {
      padding-left: 16px;
    }
  }
}
