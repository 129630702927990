.page-body {
  padding-bottom: 50px;

  &.align-to-edge {
    padding-top: 0;
    padding-bottom: 0;
  }

  &.align-to-menu,
  &.align-to-menu-edge {
    padding-top: $menu-height;
  }

  &.align-to-menu-edge {
    padding-bottom: 0;
  }

  &.align-to-content-start {
    padding-top: $menu-height + 32px;
  }

  @include breakpoint($breakMedium) {
    &.align-to-menu,
    &.align-to-menu-edge {
      padding-top: $menu-height-medium;
    }
    &.align-to-content-start {
      padding-top: $menu-height-medium + 46px;
    }
  }

  @include breakpoint($breakLarge) {
    &.align-to-menu,
    &.align-to-menu-edge {
      padding-top: $menu-height-big;
    }
    &.align-to-content-start {
      padding-top: $menu-height-big + 46px;
    }
  }
}
