.expand-content-symbol {
  display: inline-block;
  transform-origin: center center;
  transition: 0.3s transform ease;
  border-radius: 50%;
  line-height: 0;
  will-change: transform;

  &--white {
    width: 14px;
    height: 14px;
    padding: 2px;
    background-color: #fff;

    .icon-chevron-down {
      width: 10px;
      height: 10px;
      fill: $background;
    }
  }

  &--grey {
    width: 18px;
    height: 18px;
    padding: 3px;
    background-color: #ddd;

    .icon-chevron-down {
      width: 12px;
      height: 12px;
      fill: $blue;
    }
  }

  &--bluegrey {
    width: 14px;
    height: 14px;
    padding: 3px;
    background-color: #055fa2;

    .icon-chevron-down {
      width: 8px;
      height: 8px;
      fill: #fff;
    }
  }

  &--active {
    transform: rotate(-180deg);

    &.expand-content-symbol--bluegrey {
      background-color: #9b9b9b;
    }
  }

  .icon-chevron-down {
    line-height: 1;
  }

  @include breakpoint($breakLarge) {
    &--bluegrey {
      width: 21px;
      height: 21px;
      padding: 4px;

      .icon-chevron-down {
        width: 13px;
        height: 13px;
      }
    }
  }
}
