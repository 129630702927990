.url-sharer {
  transition: 0.25s opacity;

  &--loading {
    opacity: $disabled-opacity;
    pointer-events: none;
  }

  .social-header {
    font-size: 16px;
  }

  .social-icon {
    width: 40px;
    height: 40px;
  }

  .pdf-wrapper {
    margin: 24px 0;
    text-align: center;

    a {
      display: inline-block;
      font-size: 12px;
      text-decoration: none;

      span.sprite {
        margin-top: 4px;
        margin-bottom: 8px;
      }

      span.label {
        display: block;
      }

      @include hover {
        .sprite {
          opacity: $hover-opacity;
        }
        .title {
          color: $hover-white;
        }
      }
    }
  }

  .button-list.social-list {
    max-width: 250px;

    li {
      margin-bottom: 12px;
    }

    .fgrid-cell:first-child {
      flex-basis: 22%;
    }
  }

  input {
    display: block;
    width: 100% !important;
    height: 40px !important;
    margin: 16px 0 4px;
    padding-right: 120px;
    border: 0;
    background-color: #fff;
    font-size: 12px;
  }

  .copy-button {
    position: absolute;
    top: 5px;
    right: 6px;
    width: 94px;
    height: 30px;
    border: 1px solid #ddd;
    border-radius: 3px;
    background-color: #f2f2f2;
    color: #0096ff;
  }

  .copy-feedback {
    position: absolute;
    top: -23px;
    left: calc(50% - 70px);
    width: 140px;
    padding: 6px 4px;
    transition: 0.25s opacity;
    border-radius: 3px;
    opacity: 0;
    background-color: #272727;
    box-shadow: 2px 2px 5px rgba(#1a1a1a, 0.4);
    font-size: 10px;
    text-align: center;

    &--visible {
      opacity: 1;
    }
  }
}
