.container {
  display: flex;
  position: relative;
  height: 25px;
  overflow: hidden;
  background-color: #fff;

  select {
    width: 130%;
    height: 25px;
    padding: 0 5px;
    border: 0;
    background: transparent;
    background-image: none;
    box-shadow: none;
    appearance: none;

    &:focus {
      outline: none;
    }
  }

  select:focus + .arrow {
    background-color: darken(#0093ff, 15%);
  }
}

.arrowContainer {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  align-items: center;
  width: 30px;
  pointer-events: none;
}

.arrow {
  display: block;
  margin: 0 auto;
}

.blue {
  .arrowContainer {
    background-color: #0093ff;
  }

  .arrow {
    width: 0;
    height: 0;
    border-top: 4px solid #fff;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
  }
}

.transparent {
  .arrow {
    display: inline-block;
    padding: 2px;
    transform: rotate(45deg);
    border: solid #838383;
    border-width: 0 2px 2px 0;
  }
}

.width55 {
  width: 55px;
}

.width80 {
  width: 80px;
}

.width125 {
  width: 125px;
}

.width150 {
  width: 150px;
}

.width220 {
  width: 220px;
}
