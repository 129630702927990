.radio-input {
  display: flex;
  align-items: center;

  & + .radio-input {
    padding-left: 15px;
  }

  &--disabled,
  &.disabled {
    opacity: 0.5;
  }

  input[type='radio'] {
    position: absolute;
    // Hide and let <label> element render input ui
    opacity: 0;
  }

  .checkmark {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 18px;
    height: 18px;
    border: 1px solid #ddd;
    border-radius: 50%;
    background-color: #fff;

    &__inner {
      display: inline-block;
      width: 8px;
      height: 8px;
      transition: opacity 0.25s;
      border-radius: 50%;
      opacity: 0;
      background-color: #0093ff;
    }

    &--checked {
      .checkmark__inner {
        opacity: 1;
      }
    }
  }

  .label-text {
    margin-left: 5px;
  }

  @include breakpoint($breakLarge) {
    .checkmark {
      width: 20px;
      height: 20px;

      &__inner {
        width: 10px;
        height: 10px;
      }
    }
  }
}
